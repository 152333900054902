<template>
  <div class="create-store-application-form">
    <v-form ref="form" v-model="computedValid">
      <div class="subtitle-1 mb-2">
        {{ $t("__store_type") }} <span class="error--text">*</span>
      </div>
      <v-select
        outlined
        dense
        :value="computedForm['store_industry_type']"
        @input="setForm('store_industry_type', $event)"
        :items="storeType"
        item-text="ch"
        item-value="en"
        :rules="[requiredRule]"
        required
      />

      <div class="subtitle-1 mb-2">
        {{ $t("__store_name") }} <span class="error--text">*</span>
      </div>
      <v-text-field
        outlined
        dense
        :value="computedForm['store_name']"
        @input="setForm('store_name', $event)"
        type="text"
        :rules="[requiredRule]"
        required
      />

      <div class="subtitle-1 mb-2">
        {{ $t("__owner_name") }} <span class="error--text">*</span>
      </div>
      <v-text-field
        outlined
        dense
        :value="computedForm['owner_name']"
        @input="setForm('owner_name', $event)"
        type="text"
        :rules="[requiredRule]"
        required
      />

      <div class="subtitle-1 mb-2">
        {{ $t("__owner_phone_number") }} <span class="error--text">*</span>
      </div>
      <phone-number-input
        outlined
        dense
        :value="computedForm['owner_phone_number']"
        @input="setForm('owner_phone_number', $event)"
        :rules="[requiredRule]"
        required
      />

      <div class="subtitle-1 mb-2">
        {{ $t("__owner_email_address") }} <span class="error--text">*</span>
      </div>
      <v-text-field
        outlined
        dense
        :value="computedForm['owner_email']"
        @input="setForm('owner_email', $event)"
        type="email"
        :rules="[requiredRule, emailRule]"
        required
      />

      <div class="subtitle-1 mb-2">{{ $t("__store_owner_type") }}</div>
      <v-radio-group
        :value="computedForm['store_owner_type']"
        @change="setForm('store_owner_type', $event)"
        row
      >
        <v-radio
          v-for="v of storeOwnerType"
          :key="v"
          :label="$t(`__store_owner_type_${v}`)"
          :value="v"
        />
      </v-radio-group>

      <template v-if="computedForm['store_owner_type'] === 'company'">
        <div class="subtitle-1 mb-2">
          {{ $t("__tax_id_number") }} <span class="error--text">*</span>
        </div>
        <v-text-field
          outlined
          dense
          :value="computedForm['tax_id_number']"
          @input="setForm('tax_id_number', $event)"
          type="number"
          :rules="[requiredRule, taxIDNumberRule]"
          required
        />
      </template>

      <div class="subtitle-1 mb-2">
        {{ $t("__store_phone_number") }} <span class="error--text">*</span>
      </div>
      <phone-number-input
        outlined
        dense
        :value="computedForm['store_phone_number']"
        @input="setForm('store_phone_number', $event)"
        :rules="[requiredRule]"
        required
      />

      <div class="subtitle-1 mb-2">
        {{ $t("__store_address_detail") }} <span class="error--text">*</span>
      </div>
      <address-input :address.sync="form['store_address_detail']" />
      <v-container fluid>
        <v-row>
          <v-col class="pa-0" :cols="isMobile ? 12 : 3">
            <div class="subtitle-1 mb-2">
              {{ $t("__store_bank_code") }} <span class="error--text">*</span>
            </div>
            <v-combobox
              outlined
              dense
              :value="computedForm['store_bank_code']"
              @input="setForm('store_bank_code', $event)"
              :items="bankCodes"
              item-value="code"
              item-text="text"
              :return-object="false"
              :rules="[requiredRule, bankCodeRule]"
            />
          </v-col>
          <v-col class="pa-0" :cols="isMobile ? 0 : 1" />
          <v-col class="pa-0" :cols="isMobile ? 12 : 8">
            <div class="subtitle-1 mb-2">
              {{ $t("__store_bank_account") }}
              <span class="error--text">*</span>
            </div>
            <v-text-field
              outlined
              dense
              :value="computedForm['store_bank_account']"
              @input="setForm('store_bank_account', $event)"
              type="number"
              :rules="[requiredRule]"
              required
            />
          </v-col>
        </v-row>
      </v-container>

      <div class="subtitle-1 mb-2">{{ $t("__store_website") }}</div>
      <v-text-field
        outlined
        dense
        :value="computedForm['store_website']"
        @input="setForm('store_website', $event)"
        type="url"
        :rules="[urlRule]"
      />

      <!-- hide telegram column -->
      <!-- <div class="subtitle-1 mb-2">{{ $t('__telegram_bot_token') }}</div>
      <v-text-field
        outlined
        dense
        :value="computedForm['telegram_bot_token']"
        @input="setForm('telegram_bot_token', $event)"
        type="text"
        :rules="[ telegramBotTokenRule ]"
        :error-messages="telegramBotTokenErrorMessage"/>
      <div class="caption mb-2">{{ $t('__telegram_bot_id') }}</div>
      <v-text-field
        outlined
        dense
        v-model="telegramBotID"
        type="text"
        readonly/> -->
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BankCodesCSV from "raw-loader!../assets/bank_codes.csv";

export default {
  name: "CreateStoreApplicationForm",
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          store_name: "",
          store_industry_type: "retail",
          owner_name: "",
          owner_phone_number: "",
          owner_email: "",
          store_owner_type: "company",
          tax_id_number: "",
          store_phone_number: "",
          store_address_detail: {
            postal_code: "000",
            city: "",
            area: "",
            road: "",
            lane: "",
            alley: "",
            number: 0,
            sub_number: "",
            floor: "",
            room: ""
          },
          store_bank_code: "",
          store_bank_account: "",
          store_website: "",
          telegram_bot_token: ""
        };
      }
    },
    valid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    }),
    computedForm: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("update:form", val);
      }
    },
    computedValid: {
      get() {
        return this.valid;
      },
      set(val) {
        this.$emit("update:valid", val);
      }
    }
  },
  data() {
    return {
      requiredRule: v => !!v || this.$t("__required"),
      emailRule: v =>
        /^.+@.+\..+$/.test(v) || this.$t("__email_address_invalid"),
      taxIDNumberRule: v =>
        /^\d{8}$/.test(v) || this.$t("__tax_id_number_invalid"),
      bankCodeRule: v => /^\d{3}$/.test(v) || this.$t("__bank_code_invalid"),
      postCodeRule: v =>
        /^\d{5}|^\d{3}$/.test(v) || this.$t("__postal_code_invalid"),
      cityRule: v => this.cityRoads.includes(v) || this.$t("__city_invalid"),
      areaRule: v =>
        this.computedAreas.includes(v) || this.$t("__area_invalid"),
      roadRule: v =>
        this.computedRoads.includes(v) || this.$t("__road_invalid"),
      addressNumRule: v => /(^[1-9]\d*$)/.test(v) || this.$t("__num_invalid"),
      urlRule: v => !v || this.isURL(v) || this.$t("__url_invalid"),
      telegramBotTokenRule: v =>
        !v || /^\d+:.+$/.test(v) || this.$t("__telegram_bot_token_invalid"),
      storeOwnerType: ["company", "personal"],
      storeType: [
        {
          ch: this.$t("__store_type_catering"),
          en: "catering"
        },
        {
          ch: this.$t("__store_type_retail"),
          en: "retail"
        },
        {
          ch: this.$t("__store_type_service"),
          en: "service"
        }
      ],
      bankCodes: [],
      telegramBotID: "",
      telegramBotTokenErrorMessage: ""
    };
  },
  methods: {
    parseBankCodes() {
      const rows = BankCodesCSV.split("\n");
      for (const row of rows) {
        const cells = row.split(",");
        const code = cells[0];
        const name = cells[1];
        this.bankCodes.push({
          code,
          name,
          text: `${code} - ${name}`
        });
      }
    },
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    getTelegramBotID(token) {
      return this.telegramBotGetMe(token).then(res => {
        return res.ok ? res.result.username : "";
      });
    },
    telegramBotGetMe(token) {
      return fetch(`https://api.telegram.org/bot${token}/getMe`).then(
        response => {
          return response.json();
        }
      );
    },
    setForm(name, val) {
      let form = this.form;
      form[name] = val;
      this.computedForm = form;
    },
    validate() {
      this.$refs.form.validate();
    }
  },
  created() {
    this.parseBankCodes();
  },
  watch: {
    "computedForm.telegram_bot_token": {
      handler(val) {
        this.telegramBotID = "";
        if (val) {
          this.getTelegramBotID(val).then(id => {
            this.telegramBotID = id;
            this.telegramBotTokenErrorMessage = id
              ? ""
              : this.$t("__telegram_bot_token_invalid");
          });
        }
      }
    }
  }
};
</script>
